import React from "react"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCloud, faProjectDiagram } from "@fortawesome/free-solid-svg-icons"
import { faMicrosoft } from "@fortawesome/free-brands-svg-icons"

const ServiceSection = React.forwardRef<HTMLElement>((_, ref) => (
  <Root ref={ref}>
    <Services>
      <Service>
        <ServiceIcon>
          <FontAwesomeIcon icon={faCloud} />
        </ServiceIcon>
        <ServiceTitle>
          Cloud Migration
        </ServiceTitle>
        <ServiceSubTitle>
          Microsoft Azure の導入・移行支援
        </ServiceSubTitle>
        <ServiceDescription>
          アーキテクチャからソースコードの観点まで、Microsoft Azure の導入・移行を加速するための導入・移行プログラムを実施します
      </ServiceDescription>
      </Service>
      <Service>
        <ServiceIcon>
          <FontAwesomeIcon icon={faMicrosoft} />
        </ServiceIcon>
        <ServiceTitle>
          ZEN Advisor
        </ServiceTitle>
        <ServiceSubTitle>
          Azure / DevOps 技術サポート
        </ServiceSubTitle>
        <ServiceDescription>
          Microsoft Azure および GitHub の活用について、お客様プロジェクトで発生した疑問や課題を、スペシャリストが解決をサポートします
        </ServiceDescription>
      </Service>
      <Service>
        <ServiceIcon>
          <FontAwesomeIcon icon={faProjectDiagram} />
        </ServiceIcon>
        <ServiceTitle>
          DevOps Accelerator
        </ServiceTitle>
        <ServiceSubTitle>
          DevOps / アジャイル導入推進
        </ServiceSubTitle>
        <ServiceDescription>
          15 年以上に渡る経験とノウハウに基づき、アジャイル開発プロセスの導入やプロジェクトへの適用、メンバーの育成をサポートします
        </ServiceDescription>
      </Service>
    </Services>
  </Root>
))

const Root = styled.section`
  background-color: ${(props) => props.theme.secondaryLightColor};

  @media ${(props) => props.theme.maxSmDown} {
    padding: 80px 30px;
  }

  @media ${(props) => props.theme.minMdUp} {
    padding: 100px 30px;
  }
`

const Services = styled.ul`
  display: flex;
  justify-content: space-between;
  max-width: 1170px;
  margin: 0 auto;
  list-style: none;

  @media ${(props) => props.theme.maxSmDown} {
    flex-wrap: wrap;
  }
`

const Service = styled.li`
  @media ${(props) => props.theme.maxSmDown} {
    &:not(:last-of-type) {
      margin-bottom: 30px;
    }
  }

  @media ${(props) => props.theme.minMdUp} {
    width: calc(33.3%);
    max-width: 350px;
    &:not(:last-of-type) {
      margin-right: 40px;
    }
  }

`

const ServiceIcon = styled.div`
  text-align: center;
  font-size: 70px;
  color: ${(props) => props.theme.primaryColor};
`

const ServiceTitle = styled.div`
  text-align: center;
  font-size: 40px;
  line-height: 1;
  font-family: 'Teko', sans-serif;
  text-transform: uppercase;
`

const ServiceSubTitle = styled.div`
  text-align: center;
  font-size: 14px;
  font-weight: 300;
`

const ServiceDescription = styled.p`
  margin-top: 20px;
  text-align: justify;
  line-height: 1.5;
`

export default ServiceSection
