import React, { MouseEventHandler } from "react"
import styled from "styled-components"
import { navigate } from "gatsby"
import logoImage from "@/images/logo-inverse.svg"
import hackathonImage from "@/images/hackathon.jpg"

const HackathonSection: React.FC = () => {
  const handleClickMoreButton: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault()
    navigate('/hackathon')
  }

  return (
    <Root>
      <Content>
        <Image src={hackathonImage} />
        <Text>
          <Title>
            <TitleMain>
              Azure Light-up
            </TitleMain>
            <TitleSub>
              by
              <Logo src={logoImage} />
            </TitleSub>
          </Title>
          <Description>
            日本で最も高い技術力を持つ Microsoft MVP (Most Valuable Professional) を中心に「ドリームチーム」を結成し、
            数日でクラウドネイティブデザインのシステムに生まれ変わらせるプログラムです。
          </Description>
          <Button onClick={handleClickMoreButton}>詳しく見る</Button>
        </Text>
      </Content>
    </Root>
  )
}

const Root = styled.section`
  background-color: ${(props) => props.theme.secondaryLightColor};

  @media ${(props) => props.theme.minMdUp} {
    padding: 80px 30px;
  }

  @media ${(props) => props.theme.maxSmDown} {
    padding: 0 30px 40px;
  }
`

const Content = styled.div`
  max-width: 1170px;
  margin: 0 auto;
  display: flex;

  @media ${(props) => props.theme.maxSmDown} {
    flex-wrap: wrap;
    flex-direction: column;
  }
`

const Text = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media ${(props) => props.theme.minMdUp} {
    width: 50%;
    margin-left: 60px;
  }

  @media ${(props) => props.theme.maxSmDown} {
    width: 100%;
    margin-top: 40px;
  }
`

const Title = styled.div`
  font-size: 12px;
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  @media ${(props) => props.theme.maxSmDown} {
    flex-direction: column;
  }
`

const TitleMain = styled.span`
  font-family: 'Teko', sans-serif;
  white-space: nowrap;

  @media ${(props) => props.theme.minMdUp} {
    font-size: 40px;
    margin-right: 14px;
  }

  @media ${(props) => props.theme.maxSmDown} {
    font-size: 30px;
  }
`

const TitleSub = styled.div`
  display: flex;
  align-items: center;
`

const Logo = styled.img`
  height: 10px;
  margin-left: 6px;
`

const Description = styled.p`
  line-height: 1.75;
  color: ${(props) => props.theme.secondaryDarkColor};
  text-align: justify;
`

const Button = styled.button`
  background: linear-gradient(to left, #409EA6 0%, #2C6C8B 51%, #203F56 100%);
  width: 200px;
  font-size: 18px;
  appearance: none;
  border: none;
  padding: 14px 0;
  color: ${(props) => props.theme.textLightColor};
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.3s;
  border: 2px solid;

  @media ${(props) => props.theme.minMdUp} {
    margin-top: 30px;
  }
  @media ${(props) => props.theme.maxSmDown} {
    margin: 30px auto 0;
  }

  &:hover {
    background: ${(props) => props.theme.textLightColor};
    color: ${(props) => props.theme.primaryColor};
  }

  &:focus {
    outline: 0;
  }
`

const Image = styled.img`
  object-fit: cover;

  @media ${(props) => props.theme.minMdUp} {
    height: 350px;
    width: 50%;
    border-radius: 4px;
  }

  @media ${(props) => props.theme.maxSmDown} {
    height: 250px;
    margin-top: 40px;
    width: 100%;
  }
`

export default HackathonSection
