import React, { useRef } from "react"
import Header from "@/components/Header"
import ServiceSection from "@/components/ServiceSection"
import MsPartnerSection from "@/components/MsPartnerSection"
import ProcessSection from "@/components/ProcessSection"
import HackathonSection from "@/components/HackathonSection"
import AdvisorSection from "@/components/AdvisorSection"
import GitHubSection from "@/components/GitHubSection"
import { PageRendererProps } from "gatsby"
import animatedScroll, { easeInOutCubic } from "@/utils/animated-scroll"
import SEO from "@/components/SEO"

type IndexPageProps = PageRendererProps

const Index: React.FC<IndexPageProps> = ({ location }) => {
  const serviceSectionDOMRef = useRef<HTMLElement>(null)

  const handleClickScrollButtonInHeader = () => {
    const serviceSectionDOM = serviceSectionDOMRef.current
    if (serviceSectionDOM) {
      animatedScroll(pageYOffset, serviceSectionDOM.getBoundingClientRect().top + window.pageYOffset - 130, 1000, easeInOutCubic)
    }
  }

  return (
    <>
      <SEO pathname={location.pathname} />
      <Header onClickScrollButton={handleClickScrollButtonInHeader} />
      <ServiceSection ref={serviceSectionDOMRef} />
      <ProcessSection />
      <AdvisorSection />
      <GitHubSection />
      <HackathonSection />
      <MsPartnerSection />
    </>
  )
}

export default Index
