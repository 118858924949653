import React, { MouseEventHandler } from "react"
import styled from "styled-components"
import symbolImage from "@/images/symbol.svg"
import logoImage from "@/images/logo.svg"
import headerBackgroundImage from "@/images/header-bg.jpg"
import FadeIn from "@/components/FadeIn"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleDoubleDown } from "@fortawesome/free-solid-svg-icons"

interface HeaderProps {
  onClickScrollButton: () => void
}

const Header: React.FC<HeaderProps> = ({ onClickScrollButton }) => {
  const handleClickScrollButton: MouseEventHandler<HTMLAnchorElement> = event => {
    event.preventDefault()
    onClickScrollButton()
  }
  return (
    <Root>
      <BackGround></BackGround>
      <Content>
        <FadeIn delay={200}>
          <Introduction>
            <Symbol src={symbolImage} />
            <Heading>
              <Logo src={logoImage} />
              <Copy>Hack Everything.</Copy>
            </Heading>
          </Introduction>
        </FadeIn>
      </Content>
      <ScrollDown>
        <ScrollDownButton onClick={handleClickScrollButton}>
          <FontAwesomeIcon icon={faAngleDoubleDown} fixedWidth />
        </ScrollDownButton>
      </ScrollDown>
    </Root>
  )
}

const Root = styled.header`
  width: 100%;
  background-color: #000;
  position: relative;

  @media ${props => props.theme.maxSmDown} {
    height: 80%;
  }

  @media ${props => props.theme.minMdUp} {
    height: 700px;
  }
`

const BackGround = styled.div`
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(${headerBackgroundImage});
  background-position: center;
  filter: brightness(50%) contrast(120%);
`

const Content = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media ${props => props.theme.maxMdDown} {
    width: 80%;
  }
  @media ${props => props.theme.minLgUp} {
    width: 1170px;
  }
`

const Introduction = styled.div`
  color: white;

  @media ${props => props.theme.maxSmDown} {
    margin-top: 25px;
  }
  @media ${props => props.theme.maxMdDown} {
    text-align: center;
  }
  @media ${props => props.theme.minLgUp} {
    display: flex;
    align-items: center;
  }
`

const Symbol = styled.img`
  @media ${props => props.theme.maxMdDown} {
    max-width: 120px;
    display: block;
    height: auto;
    margin: 0 auto;
  }
  @media ${props => props.theme.minLgUp} {
    max-height: 180px;
    display: block;
    max-width: 100%;
    height: auto;
    margin: 0 auto;
    width: 180px;
    margin-right: 30px;
  }
`

const Logo = styled.img`
  height: 16px;
`

const Heading = styled.h2`
  @media ${props => props.theme.maxMdDown} {
    margin-top: 20px;
  }
  @media ${props => props.theme.minLgUp} {
    text-align: left;
    flex-grow: 1;
  }
`

const Copy = styled.div`
  color: inherit;
  line-height: 1;
  margin-top: 10px;
  font-weight: 700;
  text-transform: uppercase;
  font-family: "Teko", sans-serif;

  @media ${props => props.theme.maxMdDown} {
    margin-top: 30px;
    font-size: 60px;
  }

  @media ${props => props.theme.minLgUp} {
    font-size: 100px;
  }
`

const ScrollDown = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 70%);
`

const ScrollDownButton = styled.a`
  text-decoration: none;
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  user-select: none;
  background-image: none;
  background-color: ${props => props.theme.secondaryLightColor};
  letter-spacing: normal;
  color: ${props => props.theme.textColor};
  font-size: 50px;
  transition: color 0.3s;
  border-radius: 100%;
  padding: 10px 20px;
  width: 100px;
  animation: upDown 2s ease-in-out infinite;

  &:hover {
    color: ${props => props.theme.secondaryColor};
  }

  @keyframes upDown {
    0% {
      transform: translate3d(0, 0, 0);
    }
    50% {
      transform: translate3d(0, -6px, 0);
    }
    100% {
      transform: translate3d(0, 0, 0);
    }
  }
`

export default Header
