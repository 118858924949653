import React from "react"
import styled from "styled-components"
import backgroundImage from "@/images/partner-bg.jpg"
import msPartnerLogoImage from "@/images/ms-partner-logo.png"

const MsPartnerSection: React.FC = () => (
  <Root>
    <BackgroundFilter />
    <Background />
    <Content>
      <MsPartnerLogo src={msPartnerLogoImage} />
      <Text>
        ゼンアーキテクツは、 Microsoft ゴールドパートナー（Gold Cloud Platform コンピテンシー取得）です。
        Azure 黎明期より PaaS / Serverless 技術に特化したエンタープライズ向けクラウドアプリケーションの導入支援・ DevOps サポートを実施してきました。
        現在ではその実績と活動内容をご評価いただき、プロジェクト支援だけでなく Microsoft 公式イベント やコミュニティにおいても Microsoft Azure の技術リーダーとして幅広く貢献しています。
      </Text>
    </Content>
  </Root>
)

const Root = styled.section`
  display: flex;
  position: relative;

  @media ${(props) => props.theme.minMdUp} {
    padding: 60px 20px 80px;
  }
`

const BackgroundFilter = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: linear-gradient(45deg, #1a2e92, #209896);
  z-index: -1;
  opacity: 0.3;
`

const Background = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: no-repeat center center scroll;
  background-size: cover;
  background-image: url(${backgroundImage});
  filter: brightness(20%) contrast(120%);
  z-index: -2;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  max-width: 1170px;
  color: ${(props) => props.theme.textLightColor};

  @media ${(props) => props.theme.maxSmDown} {
    padding: 40px 30px 60px;
  }

  @media ${(props) => props.theme.minMdUp} {
    padding: 20px 40px 40px;
  }
`

const MsPartnerLogo = styled.img`
  @media ${(props) => props.theme.maxSmDown} {
    width: 300px;
    max-width: 100%;
  }

  @media ${(props) => props.theme.minMdUp} {
    width: 400px;
  }
`

const Text = styled.p`
  text-align: justify;

  @media ${(props) => props.theme.maxSmDown} {
    margin-top: 20px;
    line-height: 1.75;
  }

  @media ${(props) => props.theme.minMdUp} {
    flex-grow: 1;
    margin-top: 40px;
    max-width: 700px;
    line-height: 2;
  }
`

export default MsPartnerSection
