import React from "react"
import styled from "styled-components"
import { navigate } from "gatsby"
import advisorImage from "@/images/zen-advisor-about.png"

const AdvisorSection: React.FC = () => {
  return (
    <Root>
      <Content>
        <Image src={advisorImage} />
        <Text>
          <Title>ZEN Advisor</Title>
          <Description>
            Azure スペシャリストによる QA サポートです。フォーラム形式でお客様のお困りごと、お悩み解決をサポートします。お客様の抱える疑問や課題をぜひご相談ください。弊社のスペシャリストが最適なサポートをご提供いたします。
          </Description>
          <Button onClick={() => navigate('zen-advisor')}>詳しく見る</Button>
        </Text>
      </Content>
    </Root>
  )
}

const Root = styled.section`
  background-color: ${(props) => props.theme.secondaryLightColor};

  @media ${(props) => props.theme.minMdUp} {
    padding: 80px 30px;
  }

  @media ${(props) => props.theme.maxSmDown} {
    padding: 40px 30px;
  }
`

const Content = styled.div`
  max-width: 1170px;
  margin: 0 auto;
  display: flex;

  @media ${(props) => props.theme.maxSmDown} {
    flex-wrap: wrap;
    flex-direction: column;
  }
`

const Text = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media ${(props) => props.theme.minMdUp} {
    width: 50%;
    margin-left: 60px;
  }

  @media ${(props) => props.theme.maxSmDown} {
    width: 100%;
    margin-top: 40px;
  }
`

const Title = styled.div`
  margin-bottom: 16px;
  font-family: 'Teko', sans-serif;
  white-space: nowrap;

  @media ${(props) => props.theme.minMdUp} {
    font-size: 40px;
  }
  @media ${(props) => props.theme.maxSmDown} {
    font-size: 30px;
    text-align: center;
  }
`

const Description = styled.p`
  line-height: 1.75;
  color: ${(props) => props.theme.secondaryDarkColor};
  text-align: justify;
`

const Button = styled.button`
  background: #409EA6;
  width: 200px;
  font-size: 18px;
  appearance: none;
  border: none;
  padding: 14px 0;
  color: ${(props) => props.theme.textLightColor};
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.3s;
  border: 2px solid;

  @media ${(props) => props.theme.minMdUp} {
    margin-top: 30px;
  }
  @media ${(props) => props.theme.maxSmDown} {
    margin: 30px auto 0;
  }

  &:hover {
    background: ${(props) => props.theme.textLightColor};
    color: #409EA6;
  }

  &:focus {
    outline: 0;
  }
`

const Image = styled.img`
  @media ${(props) => props.theme.minMdUp} {
    width: 40%;
  }

  @media ${(props) => props.theme.maxSmDown} {
    height: 250px;
    width: calc(100% + 60px);
    margin: 0 -30px;
  }
`

export default AdvisorSection
