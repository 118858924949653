import React from "react"
import styled from "styled-components"
import octocatImage from "@/images/octocat.png"

const GitHubSection: React.FC = () => {
  return (
    <Root>
      <Colored>
        <Wrapper>
          <Content>
            <div className="title">DevOps with GitHub</div>
            <Text>
              ゼンアーキテクツは、 <b>GitHub Advanced Services and Technology Partner</b> に認定されており、 GitHub との強固なコラボレーションを活かしたソリューションを提供しています。
              ゼンアーキテクツは創業より15年以上にわたり DevOps 推進サービスを実施しており、GitHub を使った DevOps においても最適な解決策を提供可能です。
            </Text>
            <button className="button" onClick={() => location.href = '/devops-github'}>詳しく見る</button>
          </Content>
          <Background>
            <img src={octocatImage} />
          </Background>
        </Wrapper>
      </Colored>
    </Root>
  )
}

const Root = styled.section`
  background-color: ${(props) => props.theme.secondaryLightColor};
  overflow: hidden;

  @media ${(props) => props.theme.minMdUp} {
    margin-top: -40px;
    padding-top: 40px;
  }
`

const Colored = styled.div`
  background-color: #2C6C8B;

  @media ${(props) => props.theme.maxSmDown} {
    padding-bottom: 30px;
  }
`

const Wrapper = styled.div`
  position: relative;

  @media ${(props) => props.theme.minMdUp} {
    max-width: 1020px;
    margin: 0 auto;
  }
`

const Content = styled.div`
  color: ${(props) => props.theme.textLightColor};
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  z-index: 1;

  @media ${(props) => props.theme.minMdUp} {
    padding: 80px 20px 100px;
    width: calc(70%);
    max-width: 640px;
  }

  @media ${(props) => props.theme.maxSmDown} {
    padding: 30px 30px 0;
    align-items: center;
  }

  .title {
    font-family: 'Teko', sans-serif;
    white-space: nowrap;

    @media ${(props) => props.theme.minMdUp} {
      font-size: 40px;
    }
    @media ${(props) => props.theme.maxSmDown} {
      font-size: 30px;
      text-align: center;
    }
  }

  .button {
    background: ${(props) => props.theme.secondaryLightColor};;
    width: 200px;
    font-size: 18px;
    appearance: none;
    border: none;
    padding: 14px 0;
    color: ${(props) => props.theme.primaryColor};
    border-radius: 30px;
    cursor: pointer;
    transition: all 0.3s;
    margin-top: 30px;

    &:hover {
      background: ${(props) => props.theme.primaryColor};
      color: ${(props) => props.theme.secondaryLightColor};;
    }

    &:focus {
      outline: 0;
    }
  }
`

const Text = styled.div`
  text-align: justify;

  @media ${(props) => props.theme.minMdUp} {
    line-height: 2;
    margin-top: 20px;
  }

  @media ${(props) => props.theme.maxSmDown} {
    margin-top: 10px;
    line-height: 1.75;
  }
`

const Background = styled.div`
  @media ${(props) => props.theme.minMdUp} {
    position: absolute;
    left: calc(100% - 500px);
    right: 0;
    top: -40px;
    bottom: 0;

    img {
      height: 100%;
    }
  }

  @media ${(props) => props.theme.maxSmDown} {
    height: 300px;
    margin-top: 30px;
    display: flex;
    justify-content: center;

    img {
      height: 100%;
    }
  }
`

export default GitHubSection