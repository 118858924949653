import React from "react"
import styled, { css } from "styled-components"

const ProcessSection: React.FC = () => (
  <Root>
    <Processes>
      <ProcessPaas>
        <ProcessTitle>PaaS / Serverlessの活用</ProcessTitle>
        <Line></Line>
        クラウドネイティブなアーキテクチャを実現しやすくするため、PaaS / Serverless 活用を推奨しています
      </ProcessPaas>
      <ProcessAzure>
        <ProcessTitle>最新のAzureサービスを採用</ProcessTitle>
        <Line></Line>
        パブリッククラウドのサービスは陳腐化しやすいため、その時点での新サービス、バージョンの利用を推奨します
      </ProcessAzure>
      <ProcessDevops>
        <ProcessTitle>DevOps / IaC の徹底</ProcessTitle>
        <Line></Line>
        クラウドネイティブアプリケーションは継続的な開発が不可欠のため、Ops の省力化・自動化の徹底を推奨します
      </ProcessDevops>
      <ProcessArchitecture>
        <ProcessTitle>アーキテクチャパターンの活用</ProcessTitle>
        <Line></Line>
        車輪の再発明を防ぎ、ムダな開発を生まないためにも、先人の知恵を生かしたパターンの活用を推奨します
      </ProcessArchitecture>
    </Processes>
  </Root>
)

const Root = styled.section``

const Processes = styled.div`
  display: flex;
  background-color: ${(props) => props.theme.secondaryLightColor};

  @media ${(props) => props.theme.maxSmDown} {
    flex-wrap: wrap;
  }
`

const processStyle = css`
  text-align: justify;
  color: ${(props) => props.theme.textLightColor};
  line-height: 1.75;

  @media ${(props) => props.theme.minMdUp} {
    padding: 60px 40px;
    width: 25%;
  }

  @media ${(props) => props.theme.maxSmDown} {
    padding: 60px 30px;
    width: 100%;
  }
`

const ProcessPaas = styled.div`
  ${processStyle}
  background-color: #409EA6;
`

const ProcessAzure = styled.div`
  ${processStyle}
  background-color: #2C6C8B;
`

const ProcessDevops = styled.div`
  ${processStyle}
  background-color: #203F56;
`

const ProcessArchitecture = styled.div`
  ${processStyle}
  background-color: #5A74A8;
`

const ProcessTitle = styled.p`
  font-size: 20px;
  text-align: center;
`

const Line = styled.div`
  width: 20px;
  height: 9px;
  background-image: repeating-linear-gradient(to bottom, rgba(255, 255, 255, 0.4) 0, rgba(255, 255, 255, 0.4) 1px, transparent 1px, transparent 4px);
  margin: 30px auto;
`

export default ProcessSection
